import { defaults } from 'underscore'
import Callout from '../Callout'
import { spinner } from '../misc'


export default class Form {

  constructor(selector, options) {
    const defaultSelectors = {
      form: selector,
      submitButton: "[data-submit-button]",
      callout: `${selector} [data-callout]`,
      calloutCopy: "[data-callout-copy]",
      fieldSelector: "[data-form-field]",
      fieldLabel: "[data-field-label]",
      fieldError: "[data-field-error]",
      fieldInput: "[data-field-input]",
    }

    this.selectors = defaults(options.selectors, defaultSelectors)

    if(!$(this.selectors.form)) {
      return
    }

    this.ui = {}

    this.ui.form = $(selector)
    this.ui.submitButton = this.ui.form.find(this.selectors.submitButton)
    this.ui.callout = new Callout(`${this.selectors.callout}`, {})
    // this.ui.calloutCopy = this.ui.callout.find(this.selectors.calloutCopy)

    const defaultContent = {
      successMessage: 'Form submitted successfully',
      errorMessage: 'Ooops, something’s not quite right. Check out the details below.',
      loadingMessage: spinner,
      submitButtonCopy: this.ui.submitButton.html()
    }

    const defaultSettings = {
      debug: true,
      successCriteriaKey: 'valid',
      successCriteriaArray: '',
      errorMessageKey: 'error',
      successMessageKey: 'message',
      useErrorKey: false,
      useSuccessKey: false,
      ignoreErrorCallout: false,
      ignoreSuccessCallout: false,
      fieldErrorsKey: "response['errors']",
      clearLoadingAfterSuccess: true,
      clearLoadingAfterError: true
    }

    this.contentSettings = defaults(options.contentSettings, defaultContent)
    this.settings = defaults(options.settings, defaultSettings)

    this.onSubmissionStart = options.onSubmission
    this.successCallback = options.onSuccess
    this.errorCallback = options.onError
    this.onFailCallback = options.onFail
    // Event Handlers
    $('body').on("submit", `form${this.selectors.form}`, (e) => this.formSubmit(e))
  }

  formSubmit(e) {
    e.preventDefault()
    this.ui.target = $(e.target)
    this.setUIElements()
    var submissionPass = this.onSubmissionStart && this.onSubmissionStart.call(this);
     if (submissionPass === undefined) {
      submissionPass = true;
    }

    return new Promise((resolve) => {
      if(submissionPass) {
        resolve(this.submit(e));
      }
    })
  }

  submit(e) {
    e.preventDefault()
    var data = new FormData(e.target)
    this.data = data

    this.onFormSubmissionStart()
    .then(
      submitFormData(data)
      .then((response) => {
        if(this.settings.successCriteriaArray) {
          var successCrit = eval(this.settings.successCriteriaArray)
        } else {
          var successCrit = response[this.settings.successCriteriaKey]
        }
        if(successCrit) {
          this.success(response)
          .then(() => {
            return this.successCallback && this.successCallback.call(this, response)
          })
          .then(() => {
            if(this.settings.clearLoadingAfterSuccess) {
              this.clearLoading()
            }
          })
          } else {
            this.error(response)
            .then(() => {
              return this.errorCallback && this.errorCallback.call(this, response)
            })
             .then(() => {
              if(this.settings.clearLoadingAfterError) {
                this.clearLoading()
              }
            })
          }
          }).catch(e => {
          if(this.debug) {}
          this.ui.callout.error(e.responseText)
          this.clearLoading()
      })
    );
  }

  setUIElements() {
    this.ui.form = $(this.selectors.form)
    this.ui.submitButton = this.ui.target.find(this.selectors.submitButton)
    this.ui.callout = this.ui.callout = new Callout(`${this.selectors.callout}`, {})
    // this.ui.calloutCopy = this.ui.callout.find(this.selectors.calloutCopy)
    this.contentSettings.submitButtonCopy = this.ui.submitButton.html()
  }

  onFormSubmissionStart() {
     return new Promise((resolve, reject) => {
      this.ui.callout.clear()
      this.clearErrors()
      this.showLoading()
      resolve()
    })
  }

  submission() {
    return new Promise((resolve, reject) => {
      return true;
    })
  }

  success(response) {
    return new Promise((resolve, reject) => {
      this.showSuccessCallout(response)
      resolve()
    })
  }

  error(response) {
    return new Promise((resolve, reject) => {
      this.showErrorCallout(response)
      if(eval(this.settings.fieldErrorsKey)) {
        this.showErrors(eval(this.settings.fieldErrorsKey))
      }
      resolve()
    })
  }

  showErrorCallout(response) {
    if(!this.settings.ignoreErrorCallout) {
      var errorMessage = ((this.settings.useErrorKey) && response[this.settings.errorMessageKey] ? response[this.settings.errorMessageKey] : this.contentSettings.errorMessage)
      this.ui.callout.error(errorMessage)
    }
  }

  showSuccessCallout(response) {
    if(!this.settings.ignoreSuccessCallout) {
      var successMessage = ((this.settings.useSuccessKey) ? response[this.settings.successMessageKey] : this.contentSettings.successMessage)
      this.ui.callout.success(successMessage)
    }
  }

  setFieldError(field, message) {
    field.addClass('inputItem--error')

    field.find(this.selectors.fieldError)
    .html(message)
  }

  showErrors(errors) {
    if(this.settings.debug) {
      console.log(errors)
    }
    $.each(errors, (field, error) => {
      this.setFieldError(this.selectField(field), error)
    })
  }

  selectField(id) {
    if(id.indexOf('.') !== -1) {
      var idArray = id.split('.')
      for (var i = 0; i < idArray.length; i++) {
        if(i == 0) {
          id = idArray[i]
        } else {
          id += '[' + idArray[i] + ']'
        }
      }
    }
    return this.ui.target.find(`[data-form-field='${id}']`)
  }

  clearErrors() {
    this.ui.target.find(this.selectors.fieldSelector).removeClass('inputItem--error')

    this.ui.target.find(this.selectors.fieldError)
   .html()
  }

  showLoading() {
    this.ui.submitButton.html(this.contentSettings.loadingMessage).attr('disabled', 'disabled')
  }

  clearLoading() {
    this.ui.submitButton.html(this.contentSettings.submitButtonCopy).attr('disabled', false)
  }
}

function submitFormData(data) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      type: 'POST',
      url: '/',
      data: data,
      processData: false,
      contentType: false,
      dataType: 'json'
    }).done(response => {
      resolve(response)
    }).fail(response => {
      reject(response)
    })
  })
}
