import { defaults } from 'underscore'

class Callout {
  constructor(selector, options) {

    const defaultSelectors = {
      container: selector,
      copyContainer: "[data-callout-copy]",
      closeButton: "[data-close]",
    }

    this.selectors = defaults(options.selectors, defaultSelectors)
    this.ui = {}
    this.ui.container = $(selector)
    this.ui.copyContainer = this.ui.container.find(this.selectors.copyContainer)

    $(this.ui.container).on('click', `button${this.selectors.closeButton}`, (e) => this.clear(e));
  }

  clear() {
    var self = this
    this.ui.container.stop().slideUp(function() {
      self.ui.container.addClass('hide').removeClass('success alert')
      self.ui.container.css({"height": ""});
      self.ui.copyContainer.html('')
    })
  }

  error(message, clear=false) {
    this.show('alert', message, clear)
  }

  success(message, clear=false) {
    this.show('success', message, clear)
  }

  warning(message, clear=false) {
    this.show('warning', message, clear)
  }

  show(status, message, clear=false) {
    var self = this
    this.ui.copyContainer.html(message)
    this.ui.container.hide().removeClass('success alert hide').addClass(status)
    this.ui.container.stop().slideDown(function() {
    if(clear) {
      setTimeout(function() {
        self.clear()
      }, 3500)
      }
    })
  }
}

export default Callout
