
export default function currencyPicker(e) {
  var data = {
    action: 'commerce/cart/update-cart',
    paymentCurrency: $(e.target).val(),
    currency: $(e.target).val(),
  }

  data[csrfTokenName] = csrfTokenValue
  $.ajax({
    type: 'POST',
    url: '/',
    dataType: 'json',
    data: data
  }).done(function(response) {
    location.reload();
  })
}
