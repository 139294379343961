import { findWhere } from 'underscore'
import instantsearch from 'instantsearch.js'
import algoliasearch from 'algoliasearch'
import {connectRefinementList} from 'instantsearch.js/es/connectors';

import { close, chevRight, chevLeft, searchIcon, eye } from '../misc'


import { searchBox, refinementList, hits, pagination, index as setIndex, configure, widgets, breadcrumb, hierarchicalMenu } from 'instantsearch.js/es/widgets'

const searchClient = algoliasearch(
  algoliaAppID,
  algoliaApiKey
);

const search = (index, SF = function(helper) { helper.search() }) => {
  return instantsearch({
    indexName: `${index}`,
    searchClient,
    searchFunction(helper) {
      return SF(helper)
    }
  });
}

const results = (container, classClasses = resultsClasses) => {
  return hits({
    container: container,
    cssClasses: classClasses,
    templates: {
      item: (data) => {
        switch(data.type) {
          default:
            return cardDefault(data)
        }
      },
      empty: (data) => {

      }
    },
  })
}

const addIndex = (index) => {
  return setIndex({
    indexName: `${index}`
  })
}

const singlePageConfig = () => {
  return configure({
    hitsPerPage: 5,
  })
}

const searchBar = () => {
  return searchBox({
    container: '.siteSearch',
    cssClasses: {
      root: 'portalSearch__root',
      form: 'portalSearch__form',
      input: 'portalSearch__input',
      reset: 'portalSearch__reset',
      resetIcon: 'portalSearch__resetIcon',
      loadingIndicator: 'portalSearch__loadingIndicator',
      submit: 'portalSearch__submit',
      submitIcon: 'portalSearch__submitIcon'
    },
    showReset: false,
    showSubmit: true,
    placeholder: 'Search',
    templates: {
      submit() {
        return `${searchIcon}`;
      },
    },
  })
}

const paginate = (container) => {
  return pagination({
    container: container,
    showLast: false,
    showFirst: false,
    padding: 0,
    scrollTo: false,
    cssClasses: {
      root: 'portalPagination__root',
      noRefinementRoot: '',
      list: 'portalPagination__list',
      item: 'portalPagination__item',
      firstPageItem: '',
      lastPageItem: '',
      previousPageItem: 'portalPagination__previous',
      nextPageItem: 'portalPagination__next',
      pageItem: 'portalPagination__item',
      selectedItem: 'portalPagination__item--selected',
      disabledItem: 'portalPagination__disabled',
      link: 'portalPagination__link',
    },
    templates: {
      previous: `${chevLeft}`,
      next: `${chevRight}`
    }
  })
}

const cardDefault = (data) => {
  var template = ''
  var type = data.type.toLowerCase()
  template +=  `
    <a class="portalCard" href="${data.url}">
      <div class="portalCard__header" style="background-image: url(${data.artwork}?fit=${data.fitMethod}&crop=faces,entropy&w=600&h=300)">
        <div class="portalCard__chip">
          <i class="portalIcon portalIcon__${type}"></i> <span class="portalCard__chipText">${data.type}</span>
        </div>
        </div>
        <div class="portalCard__content">
          <h1 class="portalCard__title capitalize">${data._highlightResult.title.value}</h1>`

  template += `
    </div>
  </a>`

  return template
}

export { search, addIndex, singlePageConfig, results, searchBar, paginate }
