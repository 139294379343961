require("../css/app.pcss");
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import "lazysizes";

window.Alpine = Alpine;
Alpine.plugin(collapse);

Alpine.start();

import Slideout from "slideout";
import "./components/header";
import currencyPicker from "./components/CurrencySwitcher";
import { floatingFormLabels } from "floating-form-labels/dist/floatingFormLabels.min.js";
import { defaults, sortBy, uniq } from "underscore";
import Form from "./components/Form";
import {
  search,
  singlePageConfig,
  results,
  searchBar,
  paginate,
  addIndex,
} from "./components/Search";
const resultsOverride = { list: ["searchResults__list--col"] };

const resultsClasses = {
  list: ["searchResults__list--col"],
  item: ["searchResults__item"],
};

// Search
const algolia = search("Search").addWidgets([
  searchBar(),
  singlePageConfig(),
  results(
    "#searchContent .searchResults",
    defaults(resultsOverride, resultsClasses)
  ),
  paginate("#searchContent .searchPagination"),
  addIndex("Products").addWidgets([
    results(
      document.querySelector("#searchProducts .searchResults"),
      defaults(resultsOverride, resultsClasses)
    ),
    paginate(document.querySelector("#searchProducts .searchPagination")),
  ]),
]);

algolia.start();

var slideout = new Slideout({
  panel: document.getElementById("main"),
  menu: document.getElementById("menu"),
  padding: 400,
  tolerance: 70,
  duration: 250,
  easing: "cubic-bezier(.23,1,.32,1)",
  side: "right",
});

var searchResults = new Slideout({
  panel: document.getElementById("main"),
  menu: document.getElementById("searchPanel"),
  padding: 400,
  tolerance: 70,
  duration: 250,
  easing: "cubic-bezier(.23,1,.32,1)",
  side: "right",
});

if ($(".siteHeader__menuToggle").length) {
  document
    .querySelector(".siteHeader__menuToggle")
    .addEventListener("click", function () {
      slideout.toggle();
    });
}

if ($(".siteHeader__searchButton").length) {
  document
    .querySelector(".siteHeader__searchButton")
    .addEventListener("click", function () {
      searchResults.toggle();
    });
}

if ($(".siteHeader__searchToggle").length) {
  document
    .querySelector(".siteHeader__searchToggle")
    .addEventListener("click", function () {
      searchResults.toggle();
    });
}

$(document).on("click", "[data-slideout-close]", function (e) {
  close(e);
});

$(document).on("change", ".siteHeader__currencySwitcher", currencyPicker);
$(document).on("change", ".sitefooter__currencySwitcher", currencyPicker);

var menu = slideout.menu;
slideout.menu = slideout.panel;
slideout.panel = menu;

function close(eve) {
  eve.preventDefault();
  slideout.close();
}

slideout
  .on("beforeopen", function () {
    this.panel.classList.add("panel-open");
    this.menu.classList.add("menu-open");
  })
  .on("open", function () {
    this.menu.addEventListener("click", close);
  })
  .on("beforeclose", function () {
    this.panel.classList.remove("panel-open");
    this.menu.classList.remove("menu-open");
    this.menu.removeEventListener("click", close);
  });

var searchMenu = searchResults.menu;
searchResults.menu = searchResults.panel;
searchResults.panel = searchMenu;

function searchClose(eve) {
  eve.preventDefault();
  searchResults.close();
}

searchResults
  .on("beforeopen", function () {
    this.panel.classList.add("panel-open");
    this.menu.classList.add("menu-open");
  })
  .on("open", function () {
    this.menu.addEventListener("click", searchClose);
  })
  .on("beforeclose", function () {
    this.panel.classList.remove("panel-open");
    this.menu.classList.remove("menu-open");
    this.menu.removeEventListener("click", searchClose);
  });

$(document).on("click", "button.slideout-menu-link", function () {
  var ref = $(this).data("menu-ref");
  // $(`.slideout-menu-panel[data-menu=${ref}]`).addClass('is-active');
  $(`.slideout-menu-panel[data-menu=${ref}]`).removeClass(
    "slideout-pre-hide-right"
  );
  $(this).closest(".slideout-menu-panel").addClass("slideout-pre-hide-left");
});

$(document).on("click", "button.slideout-menu-panel-header", function () {
  var ref = $(this).data("menu-ref");
  $(`.slideout-menu-panel[data-menu=${ref}]`).removeClass(
    "slideout-pre-hide-left"
  );
  $(this).closest(".slideout-menu-panel").addClass("slideout-pre-hide-right");
});

$(document).ready(function () {
  $(".inputField").floatingFormLabels({
    label: "inputField__label",
    floatedClass: "inputField--active",
    formElements: "input, textarea, select",
  });

  var forms = [];

  $(".fui-form").each(function () {
    forms[`${$(this).attr("id")}`] = new Form(`[data-form='${this.id}']`, {
      settings: {
        debug: true,
        successCriteriaKey: "success",
        successMessageKey: "submitActionMessage",
        useSuccessKey: true,
        useErrorKey: true,
        errorMessageKey: "errorMessage",
      },
      // onSubmission: function() {
      // },
      // onError: function() {
      //   window.scrollTo(this.ui.callout, top);
      // },
    });
  });

  // Click on a navigation item (top level), if it has a flyout open it.
  // Close any currently open flyouts too
  $(document).on("click", ".flyout-open", function (e) {
    var nav = document.getElementsByClassName("siteHeader__nav")[0];
    if (
      $("body").hasClass("flyout-open") &&
      !$(e.target).hasClass("siteHeader__navLink")
    ) {
      $(".flyout-overlay").removeClass("flyout-overlay--active");
      $("body").removeClass("flyout-open");
      $(".flyoutDrawer").removeClass("flyoutDrawer--active");
    }
  });

  $(document).on("click", ".siteHeader__navItem[data-flyout]", function (e) {
    if (!$("body").hasClass("flyout-open")) {
      $(".flyout-overlay").addClass("flyout-overlay--active");
      $("body").addClass("flyout-open");
    }

    $(".flyoutDrawer--active").removeClass("flyoutDrawer--active");

    var navItem = $(e.target).parent("li");
    var drawer = navItem.find(".flyoutDrawer:not('flyoutDrawer--active')");
    drawer.addClass("flyoutDrawer--active");

    // if (drawer.hasClass("flyoutDrawer--active")) {
    // } else {
    // $(".flyoutDrawer[data-flyout-drawer]:not('flyoutDrawer--active')")
    //   .fadeOut()
    //   .removeClass("flyoutDrawer--active");
    // drawer.fadeIn().addClass("flyoutDrawer--active");
    // }

    // check and show overlay
    // console.log(navItem);
    // $(".flyoutDrawer--active[data-flyout-drawer]")
    //   .fadeOut()
    //   .removeClass("flyoutDrawer--active");
    // console.log(navItem.find("[data-flyout-drawer]"));
  });

  const newsletterForm = new Form("[data-form='newsletter-form']", {
    settings: {
      debug: true,
      successCriteriaKey: "success",
      useSuccessKey: true,
      useErrorKey: true,
      successMessageKey: "submitActionMessage",
      errorMessageKey: "errorMessage",
    },
    // onSubmission: function() {
    // },
    // onError: function() {
    //   window.scrollTo(this.ui.callout, top);
    // },
  });
});
