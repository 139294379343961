import Headroom from 'headroom.js'

class Header {
  constructor(element, options) {
    this.element = $(element)
    this.getHeaderSize()
  }

  init() {
    if (!this.element.length) {
      return
    }
    this.initHeadroom()
  }

  initHeadroom() {
    const headroom = new Headroom(this.element[0], {
      offset : 159,
      tolerance : {
        down : 10,
        up : 20
      },
    })

    $(window).bind("load", () => {
      headroom.init()
      this.setHeaderSize()
      this.setFlyoutPos()
    });

    window.addEventListener("resize", (e) => {
      this.setHeaderSize()
      this.setFlyoutPos()
      headroom.offset = this.getHeaderSize()
    });
  }

  setHeaderSize() {
    $("#main").css('paddingTop', this.getHeaderSize())
  }

  setFlyoutPos() {
    $(".flyoutDrawer").css('top', this.getHeaderSize())
  }

  getHeaderSize() {
    return this.element.outerHeight()
  }
}

const header = new Header('.siteHeader', {

})

header.init()
