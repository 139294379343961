const spinner = '<div class="loading"> <svg class="loading__icon" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor"  d="M30,13.8842975 C30,16.0826446 29.4886364,18.2809917 28.4659091,20.2479339 C28.2386364,20.7107438 27.7840909,21 27.2727273,21 C27.0454545,21 26.875,20.9421488 26.6477273,20.8264463 C25.9659091,20.4793388 25.7386364,19.6694215 26.0795455,18.9752066 C26.875,17.4132231 27.2727273,15.677686 27.2727273,13.8842975 C27.2727273,7.75206612 22.3863636,2.7768595 16.3636364,2.7768595 C15.625,2.7768595 15,2.14049587 15,1.38842975 C15,0.636363636 15.625,0 16.3636364,0 C23.8636364,0 30,6.24793388 30,13.8842975 Z" fill="none"></path></svg></div>';


const close = '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>'

const chevLeft = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>`

const chevRight = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>`

const searchIcon = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>`

const check = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>`

const cross = `<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`

const eye = `<svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>`

export { spinner, close, searchIcon, chevLeft, chevRight, check, cross, eye }
